.notification-main {
  a {
    cursor: pointer;
  }

  .m-0 {
    color: #2e3a59;
  }

  .text-black {
    color: #2e3a59;
  }

  .small {
    font-size: 10px;
  }

  position: absolute;
  inset: 0px 60px auto auto;
  margin: 0;
  min-width: 350px;
  transform: translate3d(0px, 72px, 0px);

  .notification-action-main {
    display: flex;
    flex-direction: row;
    align-items: center;

    .form-switch {
      align-items: center;
      display: flex;
      margin-right: 10px;

      .form-check-input {
        margin-right: 5px;
        margin-left: 0.5em;
      }

      .form-check-input:checked {
        background-color: #0094FF !important;
        border-color: #0094FF !important;
      }

      .form-check-label {
        margin-top: 2px;
        color: #2e3a59;
      }
    }
  }

  .simplebar-main {
    max-height: 230px;
    overflow: scroll;

    .simplebar-wrapper {
      margin: 0;

      .simplebar-mask {
        .simplebar-offset {
          right: -20px;
          bottom: 0;

          .simplebar-content-wrapper {
            height: auto;
            padding-right: 20px;
            padding-bottom: 0;
            overflow: hidden scroll;
            direction: inherit;
            -webkit-box-sizing: border-box !important;
            box-sizing: border-box !important;
            position: relative;
            display: block;
            //height: 100%;
            width: auto;
            visibility: visible;
            //overflow: auto;
            max-width: 100%;
            max-height: 100%;
            //scrollbar-width: none;
            //padding: 0 !important;

            .simplebar-content {
              padding: 0;
              font-size: 11px;

              .notification-item .d-flex {
                padding: 0.75rem 1rem;

                .avatar-xs {
                  height: 2rem;
                  width: 2rem;
                }
              }
            }
          }
        }
      }
    }
  }
}

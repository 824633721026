@import "../css/theme";

.excalidraw {
  .excalidraw-button {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.625rem;
    width: var(--button-width, var(--default-button-size));
    height: var(--button-height, var(--default-button-size));
    box-sizing: border-box;
    border-width: 1px;
    border-style: solid;
    border-color: var(--button-border, var(--default-border-color));
    border-radius: var(--border-radius-lg);
    cursor: pointer;
    background-color: var(--button-bg, var(--island-bg-color));
    color: var(--button-color, var(--text-primary-color));

    svg {
      width: var(--button-width, var(--lg-icon-size));
      height: var(--button-height, var(--lg-icon-size));
    }

    &:hover {
      background-color: var(--button-hover-bg, var(--island-bg-color));
      border-color: var(
          --button-hover-border,
          var(--button-border, var(--default-border-color))
      );
      color: var(
          --button-hover-color,
          var(--button-color, var(--text-primary-color, inherit))
      );
    }

    &:active {
      background-color: var(--button-active-bg, var(--island-bg-color));
      border-color: var(--button-active-border, var(--color-primary-darkest));
    }

    &.active {
      background-color: var(--button-selected-bg, var(--color-primary-light));
      border-color: var(--button-selected-border, var(--color-primary-light));

      &:hover {
        background-color: var(
            --button-selected-hover-bg,
            var(--color-primary-light)
        );
      }

      svg {
        color: var(--button-color, var(--color-primary-darker));
      }
    }
  }
}

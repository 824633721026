.user-invite-modal {
  .cmp-download-recording {
    border-radius: 16px;
    background-color: white;
    padding: 30px;
    //font-family: Arial, Helvetica, sans-serif;
    position: relative;

    button {
      background-color: #0073e7;
      border: none;
      border-radius: 6px;
      padding: 5px 20px;
    }

    .headinglink {
      font-size: 20px;
      line-height: 36px;
      display: flex;
      align-items: flex-start;
      color: #14143B;
      justify-content: flex-start;
      font-weight: 600;
      text-transform: unset !important;
      font-family: 'Plus Jakarta Sans', sans-serif !important;
    }
  }

  .infodataUser {
    label {
      font-size: 12px;
      margin-bottom: 4px;
    }

    .d-flex.flex-column {
      margin-bottom: 10px;

      input {
        //border:1px solid #ccc;
        //padding:5px 10px;
        width: 100%;
        box-sizing: border-box;
        outline: none;
        //border-radius:3px;
      }

      ::placeholder {
        font-family: 'Plus Jakarta Sans', sans-serif;
      }
    }
  }


  .d-flex.flex-column.todata {
    position: relative;
  }

  //.d-flex.flex-column.todata input{
  //    padding-left:35px;
  //    font-weight:600;
  //}
  .d-flex.flex-column.todata {
    .to-span {
      position: absolute;
      top: 9px;
      font-weight: bold;
      left: 10px;
    }
  }

  .row.todata {
    margin-bottom: 10px !important;
    border: 1px solid #050038 !important;
    border-radius: 4px !important;
    padding: 3px;
  }

  .d-flex.flex-column.todata .userInfoNew {
    background: #f7f7f7;
    border-radius: 6px;
    padding: 7px 20px;
  }

  .d-flex.flex-column.todata .userInfoNew i {
    margin-right: 5px;
    color: #000;
  }

  .react-tagsinput--focused {
    border-color: #cccccc !important;
  }

  .react-tagsinput {
    border: none !important;

    .react-tagsinput-input {
      width: 100% !important;
      margin-bottom: 3px !important;
    }

    span {
      input::placeholder {
        font-size: 16px !important;
      }
    }
  }

  .react-tagsinput-tag {
    background-color: #ffffff !important;
    border: none !important;
    color: #444444 !important;
    display: inline-block;
    font-size: 13px;
    font-weight: 400;
    margin-bottom: 3px !important;
    margin-right: 5px;
    padding: 5px;
  }

  .to-span-main {
    flex-wrap: inherit;
    align-content: center;
    display: flex;
    padding-left: 15px;
  }

  .access-selection {
    background: #f7f7f7 !important;
    border: none !important;
    color: #0094FF !important;
    outline: none !important;
    margin-left: 5px !important;
    width: 120px !important;
    padding: 0 !important;

    .option-black {
      color: #444444;
    }

  }

  .btns-main {
    margin-top: 20px;
    justify-content: space-between;
  }

  .access-details-span {
    padding-left: 12px;
  }

  .email-access-main {
    display: flex;
    flex-direction: row;
    gap: 5px !important;

    .todata {
      flex-grow: 1;
      padding: 4px;
    }

    .email-access-dropdown {

      width: 25% !important;

      .select-access {
        padding: 12px 10px !important;
        border-radius: 6px !important;

      }

      select {
        line-height: unset !important;
        //width: 75% !important;
      }
    }

    .team-invite-main {
      border: none !important;
      padding: 0 !important;

      .select-access {
        padding: 12px 10px !important;
        border-radius: 6px !important;
      }
    }
  }

  .people-with-access-main{
    .access-details-label {
      padding-left: 0;
      color: #14143B;
      font-weight: 600;
      font-family: 'Plus Jakarta Sans', sans-serif !important;
      margin-bottom: 10px;
    }

    .access-details-span {
      .access-details-member {
        text-transform: capitalize;
      }
    }

    .userInfoNew {
      max-height: 100px !important;
      overflow: auto !important;

      .usr-avatar {
        height: 20px;
        width: 20px;
        border-radius: 50%;
      }
    }
  }
}

.reschedule-meeting-main {
  .invited-user-main {
    margin-top: 1em !important;

    .invited-user-list-main {
      max-height: 100px !important;
      overflow: auto !important;
    }

    .usr-avatar {
      height: 20px;
      width: 20px;
      border-radius: 50%;
    }
    label {
      margin-bottom: 0.5em !important;
    }
  }

  .reminder-notification-main {
    margin-bottom: 1.5em !important;
    label {
      margin-bottom: 0.5em !important;
    }
    .duration-value {
      input {
        padding: 6px 10px !important;
      }
    }

    .duration-type {
      padding-left: 4px !important;
    }
  }
}

@import "../css/variables";

.excalidraw {
  --Switch-disabled-color: #d6d6d6;
  --Switch-track-background: white;
  --Switch-thumb-background: #3d3d3d;

  &.theme--dark {
    --Switch-disabled-color: #5c5c5c;
    --Switch-track-background: #242424;
    --Switch-thumb-background: #b8b8b8;
  }

  .Switch {
    position: relative;
    box-sizing: border-box;

    width: 40px;
    height: 20px;
    border-radius: 12px;

    transition-property: background, border;
    transition-duration: 150ms;
    transition-timing-function: ease-out;

    background: var(--Switch-track-background);
    border: 1px solid var(--Switch-disabled-color);

    &:hover {
      background: var(--Switch-track-background);
      border: 1px solid #999999;
    }

    &.toggled {
      background: var(--color-primary);
      border: 1px solid var(--color-primary);

      &:hover {
        background: var(--color-primary-darker);
        border: 1px solid var(--color-primary-darker);
      }
    }

    &.disabled {
      background: var(--Switch-track-background);
      border: 1px solid var(--Switch-disabled-color);

      &.toggled {
        background: var(--Switch-disabled-color);
        border: 1px solid var(--Switch-disabled-color);
      }
    }

    &:before {
      content: "";
      box-sizing: border-box;
      display: block;
      pointer-events: none;
      position: absolute;

      border-radius: 100%;
      transition: all 150ms ease-out;

      width: 10px;
      height: 10px;
      top: 4px;
      left: 4px;

      background: var(--Switch-thumb-background);
    }

    &:active:before {
      width: 12px;
    }

    &.toggled:before {
      width: 14px;
      height: 14px;
      left: 22px;
      top: 2px;

      background: var(--Switch-track-background);
    }

    &.toggled:active:before {
      width: 16px;
      left: 20px;
    }

    &.disabled:before {
      background: var(--Switch-disabled-color);
    }

    &.disabled.toggled:before {
      background: var(--color-gray-50);
    }

    & input {
      width: 100%;
      height: 100%;
      margin: 0;

      border-radius: 12px;

      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;

      cursor: pointer;

      &:disabled {
        cursor: unset;
      }
    }
  }
}

/* On mobile screens, the calculator should fill the entire viewport.
On larger screens, there is a fixed width/height.
@todo - Check current browser support for vw/vh units  */

.App {
  min-height: 35vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.calculator {
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12),
    0 2px 4px -1px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
}

.calculator.basic {
  height: 400px;
  width: 290px;
}

.calculator.scientific {
  height: 330px;
  width: 530px;
}

@media (max-width: 450px), (max-width: 740px) and (max-height: 450px) {
  .calculator {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100% !important;
    height: 100% !important;
    box-shadow: none;
    border-radius: 0;
  }
}

@import url("https://fonts.googleapis.com/css2?family=Noto+Sans&display=swap");

.overlay {
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  padding: 0px 5px;
  /* font-family: 'Noto Sans', sans-serif; */
  max-height: 400px;
  overflow-y: auto;
  border-radius: 14px;
}
.replySection {
  border-left: 1px solid rgb(235, 235, 235);
  margin-left: 25px;
  padding: 0px 0px 0px 15px;
}
.comment-title {
  font-family: "Noto Sans", sans-serif;
  font-size: 30px;
  font-weight: 700;
  color: #343a40;
  border-bottom: 1px solid #d6d6d6;
}
.no-comDiv {
  display: flex;
  justify-content: center;
  font-size: 18px;
  font-weight: 700;
  color: #202020d1;
  margin-top: 40px;
}
.comment-delete-btn,
.comment-close-btn {
  color: grey;
}
.comment-delete-btn:hover,
.comment-close-btn:hover {
  background-color: #ffffff !important;
}
.comment-delete-btn:active,
.comment-close-btn:active {
  background-color: #ffffff !important;
}
.comment-delete-btn:focus,
.comment-close-btn:focus {
  background-color: #ffffff !important;
  outline: none !important;
  box-shadow: #ffffff !important;
}

.comment-delete-btn:focus-within,
.comment-close-btn:focus-within {
  background-color: #ffffff !important;
  outline: none !important;
}

.board-details-main {
  .dialogContainer-1rEQ0 {
    width: 540px;
    //min-height: 338px;
  }

  .dialog__container_medium-1XKqH {
    width: 520px;
  }

  .dialog__container-3utJq {
    position: relative;
    box-shadow: 0px 4px 16px rgba(5, 0, 56, 0.12);
    pointer-events: auto;
    border-radius: 16px;
  }

  .dialog__contentWrapper-2gM-U {
    height: 100%;
    overflow-x: hidden;
    border-radius: inherit;
  }

  .dialog__container-3utJq {
    position: relative;
    box-shadow: 0px 4px 16px rgba(5, 0, 56, 0.12);
    pointer-events: auto;
    border-radius: 16px;
  }

  .dialog-fIoQ8 {
    position: absolute;
    z-index: 3100;
    display: -ms-flexbox;
    display: flex;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-align: center;
    align-items: center;
    pointer-events: none;
  }

  .dialogContent-2HqoA {
    font-size: 14px;
    padding: 40px 42px 36px 40px;
  }

  [no-prevent-default], .no-prevent-default, .scroll-y-only {
    -ms-touch-action: pan-y;
    touch-action: pan-y;
  }

  .dialog__content-3J1HW {
    position: relative;
    outline: none !important;
    padding-top: 54px;
    padding-right: 30px;
    padding-bottom: 30px;
    padding-left: 30px;
    border-radius: 16px;
    background-color: #ffffff;
    max-height: calc(90vh - 108px);
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;
    scrollbar-color: #5f5c80 transparent;
    scrollbar-width: thin;

    :focus-visible {
      outline: none !important;
    }
  }

  .dialogBody-1JG91 {
    font-size: 14px;
  }

  .pictureEditable-36EMh {
    cursor: pointer;
  }

  .picture-1o61S {
    float: left;
    height: 160px;
    width: 160px;
    position: relative;
    margin-right: 28px;
  }

  .picture-1o61S {
    .image-main {
      text-align: center;
      border-radius: 4px;
      border: 1px solid #f0f0ef;
      box-sizing: border-box;
      width: 160px;
      height: 160px;
      display: block;
      margin-bottom: 8px;

      img {
        width: 100px;
        height: 100px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
        max-width: 100%;
        max-height: 100%;
      }
    }
  }

  img {
    -webkit-touch-callout: none !important;
    -webkit-user-select: none !important;
  }

  .c-dZpov-gURzhh-variant-secondary {
    color: rgb(95, 92, 128);
    border-color: rgb(235, 235, 239);
  }

  .c-dZpov {
    text-decoration: none;
    border-width: 0px 0px 1px;
    border-top-style: initial;
    border-right-style: initial;
    border-left-style: initial;
    border-top-color: initial;
    border-right-color: initial;
    border-left-color: initial;
    border-image: initial;
    border-bottom-style: solid;
    border-bottom-color: currentcolor;
    cursor: pointer;
    transition: border 250ms ease 0s, color 250ms ease 0s;
    background: none;
    padding: var(--space-inset-none);
    margin: var(--space-offset-none);
    font: inherit;
  }

  .info-2-jne {
    width: 270px;
    float: right;
    color: #050038;
    margin-top: -11px;
  }

  .boardInfoTitle-zbPz5 {
    font-size: 20px;
    height: 40px;
    padding: 2px 0 2px;
    font-family: Formular, sans-serif;
    font-size: 24px;
    font-weight: 500;
    box-sizing: border-box;
  }

  .input-1AYRN {
    width: 100%;
    border-color: rgba(5, 0, 56, 0.24);
    border-top: none;
    border-left: none;
    border-right: none;
    border-radius: 0;
    border-width: 1px;
    background-origin: border-box;
    background-repeat: no-repeat;
    background-size: 0 1px;
    background-position: 0 100%;
    background-image: linear-gradient(90deg, rgba(5, 0, 56, 0.5), rgba(5, 0, 56, 0.5));
    transition: border-color 0.4s ease-in-out, background-size 0.4s ease-in-out;
  }

  .descriptionInput-22szU {
    margin-bottom: 20px;
    margin-top: 18px;
    overflow-y: auto;
    resize: none;
    height: auto;
    max-height: 180px;
    padding-bottom: 6px;
    padding-left: 0;
    font-size: 14px;
    overflow-x: hidden;
  }

  .input-1AYRN {
    width: 100%;
    border-color: rgba(5, 0, 56, 0.24);
    border-top: none;
    border-left: none;
    border-right: none;
    border-radius: 0;
    border-width: 1px;
    background-origin: border-box;
    background-repeat: no-repeat;
    background-size: 0 1px;
    background-position: 0 100%;
    background-image: linear-gradient(90deg, rgba(5, 0, 56, 0.5), rgba(5, 0, 56, 0.5));
    transition: border-color 0.4s ease-in-out, background-size 0.4s ease-in-out;
  }

  .table.all {
    width: 100%;
    height: 100%;
  }

  .table {
    display: table;
  }

  .info-2-jne .trBoardClassification-eFHdz {
    padding-bottom: 6px;
  }

  .info-2-jne .tr-2k_fL {
    line-height: 24px;
    display: block;
    width: 270px;
    border-top: none !important;
  }

  .info-2-jne .th-24PiA {
    padding-bottom: 6px;
    vertical-align: bottom;
    height: 20px;
    line-height: 18px;
    width: 100px;
    color: #5f5c80;
  }

  .table .td, .table .th {
    display: table-cell;
    vertical-align: top;
  }

  .info-2-jne .td-2DUow {
    padding-bottom: 6px;
    vertical-align: bottom;
    line-height: 18px;
    white-space: nowrap;
    max-width: 170px;
    text-overflow: ellipsis;
    overflow-x: hidden;
    text-transform: capitalize;
  }

  .info-2-jne .th-24PiA {
    padding-bottom: 6px;
    vertical-align: bottom;
    height: 20px;
    line-height: 18px;
    width: 100px;
    color: #5f5c80;
  }

  .info-2-jne .td-2DUow {
    padding-bottom: 6px;
    vertical-align: bottom;
    line-height: 18px;
    white-space: nowrap;
    max-width: 170px;
    text-overflow: ellipsis;
    overflow-x: hidden;
  }

  .info-2-jne .th-24PiA {
    padding-bottom: 6px;
    vertical-align: bottom;
    height: 20px;
    line-height: 18px;
    width: 100px;
    color: #5f5c80;
  }

  .info-2-jne .td-2DUow {
    padding-bottom: 6px;
    vertical-align: bottom;
    line-height: 18px;
    white-space: nowrap;
    max-width: 170px;
    text-overflow: ellipsis;
    overflow-x: hidden;
  }

  .dialogFooter-1F-MR {
    display: inline-block;
    width: 100%;
    margin-top: 28px;
  }

  .dialogFooter-1F-MR .deleteBtn-YYx-9 {
    margin-right: 7px;
  }

  .dialogFooter-1F-MR .deleteBtn-YYx-9, .dialogFooter-1F-MR .leaveBtn-33a55 {
    float: left;
  }

  .c-elTseK-dNQwFp-cv {
    color: rgb(5, 0, 56);
    border: 1px solid rgb(205, 204, 215) !important;
  }

  .c-elTseK-dNQwFp-cv:hover {
    color: rgb(5, 0, 56) !important;
    background-color: rgb(245, 245, 247) !important;
  }

  .c-elTseK-BjOby-cv {
    border-radius: 4px;
  }

  .c-elTseK-exQvpM-size-small {
    padding: 7px 16px 9px;
    font-size: 14px;
    line-height: 20px;
    height: 36px;
  }

  .c-elTseK {
    box-sizing: border-box;
    display: inline-block;
    outline: none;
    white-space: nowrap;
    text-overflow: ellipsis;
    text-align: center;
    vertical-align: middle;
    user-select: none;
    font-family: inherit;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
    font-weight: normal;
    position: relative;
    cursor: pointer;
    border: none;
    text-decoration: none;
    background-color: transparent;
  }

  .dialogFooter-1F-MR .shareBtn-2vyEI, .dialogFooter-1F-MR .duplicateBtn-AcJbE {
    margin-left: 7px;
    float: right;
  }

  .dialogFooter-1F-MR .duplicateBtn-AcJbE {
    margin-left: 7px;
    float: right;
  }
}

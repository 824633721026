@import "../css/_variables";


  .comment-main {
    width: 500px !important;
    background: none !important;
    border: none !important;
    box-shadow: none !important;
  }

  .overlay {
    background: white;
    min-width: 340px !important;
  }

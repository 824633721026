.unit-cal-main {
  .MuiInput-underline:before {
    border-bottom: 1px solid rgba(0, 0, 0, 0.42) !important;
  }
  .MuiInput-underline:after {
    border-bottom: 1px solid rgba(0, 0, 0, 0.42) !important;
  }
  .MuiSelect-select:focus {
    background-color: unset !important;
  }
  .MuiFormLabel-root.Mui-focused {
    color: grey !important;
  }
}

.cookies-modal{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: fixed;
    bottom: 30px;
    left: 30px;
    max-width: 365px;
    width: 100%;
    border-radius: 15px;
    text-align: center;
    padding: 25px 25px 30px;
    transform: translateX(0px);
    background-color: #ffffff;
    z-index: 9999;
    box-shadow: 0px 0px 10px #ccc;
    .cookies-modal-content{
        h2{
            font-size: 24px;
            font-weight: 700;
            color: #000;
            }
        button{
            background: #ED863C;
            border: none;
            outline: none;
            font-style: normal;
            font-size: 14px;
            color: #fff;
            padding: 5px 18px;
            border-radius: 3px;
        }
    }
}
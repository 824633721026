.outerModalInfo{
        position: fixed;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0,0,0,0.39);
        z-index: 9999;
        display: block;
        .innerPartModal{
            position: fixed;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            background: #FFFFFF 0% 0% no-repeat padding-box;
            border-radius: 16px;
            opacity: 1;
            display: flex;
            max-width: 1240px;
            height: 90vh;
            background-color: #ffffff;
            box-sizing: border-box;
            border-radius: 16px;
            position: relative;
            @media screen and (max-width:992px) {
                height:auto;
                width:95%;
            }
            .tpicker__close-btn{
                position: relative;
                width: 40px;
                height: 40px;
                background-color: #050038;
                border-radius: 125px;
                display: -ms-flexbox;
                display: flex;
                -ms-flex-align: center;
                align-items: center;
                -ms-flex-pack: center;
                justify-content: center;
                position: absolute !important;
                top: -23px;
                border: none;
                outline: none;
                box-shadow: none;
                right:11px;
                cursor: pointer;
                .closeButton__icon-1t3o8 {
                    width: 24px;
                    height: 24px;
                    color: #ffffff;
                }
                svg{
                    width: 24px;
                    height: 24px;
                    min-width: 24px;
                    max-width: 24px;
                    min-height: 24px;
                    max-height: 24px;
                    overflow: hidden;
                }
            }
            .outerTemplateMain{
                width:100%;
                display:flex;
                @media screen and (max-width: 767px) {
                    display:inline-block;
                }
                .leftUserNavInfo{
                    width:300px;
                    padding: 38px 48px 38px 48px;
                    display: -ms-flexbox;
                    display: flex;
                    -ms-flex-direction: column;
                    flex-direction: column;
                    -ms-flex-pack: justify;
                    justify-content: space-between;
                    box-sizing: border-box;
                    padding: 28px 20px;
                    overflow: auto;
                    @media screen and (max-width: 767px) {
                        width:100%;
                        height:200px;
                        box-sizing: border-box;
                    }
                    .navDataLeft{
                        width:100%;
                        display:inline-block;
                        .tpicker-category-root-group{
                            display: -ms-flexbox;
                            display: flex;
                            -ms-flex-direction: column;
                            flex-direction: column;
                            -ms-flex-align: start;
                            align-items: flex-start;
                            @media screen and (max-width: 767px) {
                                width:100%;
                                height:200px;
                                box-sizing: border-box;
                            }
                            .tpicker-category-navigation {
                                list-style: none;
                                margin: 0;
                                padding: 0;
                                .tpicker-category-navigation__item {
                                    margin: 0;
                                    padding: 0;
                                    .tpicker-category {
                                        font-size: 14px;
                                        line-height: 20px;
                                        margin-bottom: 4px;
                                        padding: 4px 12px;
                                        -webkit-user-select: none;
                                        -moz-user-select: none;
                                        -ms-user-select: none;
                                        user-select: none;
                                        border-radius: 20px;
                                        cursor: pointer;
                                        transition: all 0.15s ease;
                                        display: -ms-inline-flexbox;
                                        display: inline-flex;
                                        margin-left: -2px;
                                        color: #050038;
                                        font-size: 16px;
                                        line-height: 24px;
                                        cursor: pointer;
                                        &:hover{
                                            background-color: #ebebef;
                                        }
                                        &.tpicker-category--selected {
                                            background-color: #050038;
                                            color: #ffffff;
                                        }
                                    }
                                }
                            }
                            .tpicker-category-root-group__title {
                                font-weight: bold;
                                font-size: 14px;
                                line-height: 38px;
                                text-transform: uppercase;
                                letter-spacing: 1px;
                                padding-left: 10px;
                                -webkit-user-select: none;
                                -moz-user-select: none;
                                -ms-user-select: none;
                                user-select: none;
                                margin-bottom: 4px;
                                margin-top: 0;
                            }
                        }
                    }
                }
                .rightCardInfoDetals{
                    width:90%;
                    @media screen and (max-width: 767px) {
                        width:100%;
                    }
                    .tpicker__content{
                        display: -ms-flexbox;
                        display: flex;
                        -ms-flex-direction: column;
                        flex-direction: column;
                        -ms-flex: 1;
                        flex: 1;
                        padding: 0 60px 0 48px;
                        @media screen and (max-width: 767px) {
                            padding:15px;
                            width:100%;
                            box-sizing: border-box;
                        }
                        .tpicker__header{
                            display: -ms-flexbox;
                            display: flex;
                            font-size: 16px;
                            padding-bottom: 12px;
                            padding-top: 38px;
                            margin-top: 2px;
                        }
                        .tpicker-search {
                            width: 100%;
                            position: relative;
                            .tpicker-search__bar {
                                display: -ms-flexbox;
                                display: flex;
                                width: 100%;
                                box-sizing: border-box;
                                -ms-flex-align: center;
                                align-items: center;
                                position: relative;
                                border-bottom: 1px solid #9391a6;
                                padding-bottom: 8px;
                                .tpicker-search__icon {
                                    margin-right: 12px;
                                    width: 24px;
                                    height: 24px;
                                    margin-right: 0px;
                                    svg,  img {
                                        width: 24px;
                                        height: 24px;
                                        min-width: 24px;
                                        max-width: 24px;
                                        min-height: 24px;
                                        max-height: 24px;
                                        overflow: hidden;
                                    }
                                }
                                .tpicker-search__input {
                                    -ms-flex: 1 1 auto;
                                    flex: 1 1 auto;
                                    width: 100%;
                                    font-style: normal;
                                    font-stretch: normal;
                                    letter-spacing: normal;
                                    font-family: Formular, sans-serif;
                                    font-size: 16px;
                                    line-height: 24px;
                                    font-weight: normal;
                                    border: none;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                    font-size: 20px;
                                    background:none;
                                    background-color:none !important;
                                    background: none !important;
                                }
                            }
                        }
                        .tpicker-autoshow{
                            -ms-flex: 0 0 auto;
                            flex: 0 0 auto;
                            margin-left: 40px;
                            font-family: Formular, sans-serif;
                            font-size: 12px;
                            line-height: 28px;
                            font-size: 14px;
                            margin-left: 68px;
                        }
                        .rtb-checkbox {
                            position: relative;
                            display: flex;
                            align-items:center;
                            vertical-align: baseline;
                            cursor: pointer;
                            font-size: 14px;
                            line-height: 20px;
                            padding-left: 16px;
                            min-height: 20px;
                            input{
                                margin-right:10px;
                            }
                        }
                        .tpicker__content-inner{
                          .tpicker-empty-grid-state {
                            color: #cdccd7;
                          }
                            display: -ms-flexbox;
                            display: flex;
                            -ms-flex: 1 0 0px;
                            flex: 1 0 0;
                            position: relative;
                            -ms-flex-direction: column;
                            flex-direction: column;
                            .tpicker-all-templates{
                                margin-left: 0;
                                height: calc(90vh - 90px);
                                overflow-y: auto;
                                margin-right: -32px;
                                padding-right: 32px;
                                margin-right: -60px;
                                padding-right: 60px;
                                @media screen and (max-width: 767px) {
                                    padding-right:0px;
                                    height:40vh;
                                    overflow:auto;
                                    margin:0px;
                                }
                                .tpicker-all-templates-category{
                                 margin-bottom:80px;
                                 min-height: 325px;
                                 @media screen and (max-width: 767px) {
                                    min-height:auto;
                                    margin-bottom:0px;
                                 }
                                 .headingData{
                                     width:100%;
                                     display:inline-block;
                                     padding:30px 0px;
                                     h2{
                                    font-size: 28px;
                                    font-weight: 400;
                                    margin: 0;
                                    font-size: 32px;
                                    @media screen and (max-width:767px) {
                                        font-size:24px;
                                    }
                                     }
                                    .gridHeaderTitleDescription-3DcYo {
                                        margin-top: 8px;
                                        font-size: 16px;
                                    }
                                 }
                                 .cardDataInfoOuter{
                                     width:100%;
                                     display:flex;
                                     flex-wrap:wrap;
                                     .cardWidth{
                                        width: 33%;
                                        padding: 7px;
                                        @media screen and (max-width: 767px) {
                                            width:100%;
                                            box-sizing: border-box;
                                        }
                                         h3{
                                            display: -ms-flexbox;
                                            display: flex;
                                            font-family: Formular, sans-serif;
                                            font-size: 20px;
                                            font-weight: 500;
                                            line-height: 22px;
                                            margin-bottom: 6px;
                                            margin-top:10px;
                                            overflow: hidden;
                                            text-overflow: ellipsis;
                                            padding:0px;
                                        }
                                         .innerCardWidth{
                                            box-sizing: border-box;
                                            -webkit-user-select: none;
                                            -moz-user-select: none;
                                            -ms-user-select: none;
                                            user-select: none;
                                            .templateItemContent{
                                                box-sizing: border-box;
                                                position: relative;
                                                width: 100%;
                                                border: 1px solid #ebebef;
                                                border-radius: 12px;
                                                margin-bottom: 10px;
                                                .template-card-thumbnail__container{
                                                    display: -ms-flexbox;
                                                    display: flex;
                                                    -ms-flex-align: center;
                                                    //align-items: center;
                                                    -ms-flex-pack: center;
                                                    justify-content: center;
                                                    background-color: #f5f5f7;
                                                    overflow: hidden;
                                                    border-radius: 12px;
                                                    height:192px;
                                                    .template-card-thumbnail--stretch-all {
                                                        display: block;
                                                        background: transparent;
                                                        width: auto;
                                                        height: auto;
                                                    }
                                                }

                                              .opacity02 {
                                                opacity: 0.2;
                                              }
                                                .template-card-hover {
                                                  position: absolute;
                                                  top: 0;
                                                  right: 0;
                                                  bottom: 0;
                                                  padding: 16px;
                                                  width: 100%;
                                                  transition: opacity 0.45s ease;
                                                  .opacity1 {
                                                    opacity: 1 !important;
                                                    transform: unset !important;
                                                  }

                                                  .template-card-details {
                                                    width: 100%;
                                                    justify-content: space-between;
                                                    height: 100%;
                                                    display: flex;
                                                    flex-direction: column;
                                                    transition: all 0.45s ease;
                                                    opacity: 0;
                                                    transform: translateY(15px);

                                                    .template-header {
                                                      display: flex;

                                                      .template-description {
                                                        font-size: 13px;
                                                        flex-grow: 1;
                                                      }

                                                      .template-action {
                                                        padding-left: 10px;
                                                        display: flex;
                                                        cursor: pointer;

                                                        &::after {
                                                          display: none !important;
                                                        }
                                                      }

                                                      .dropdown-menu {
                                                        transform: translate3d(-16.2266px, 30px, 0px) !important;
                                                      }

                                                    }

                                                    .template-footer {
                                                      display: flex;
                                                      .use-template-button {
                                                        cursor: pointer;
                                                        padding: 8px 20px;
                                                        background: #4262ff;
                                                        border-radius: 125px;
                                                        color: white;
                                                        margin-left: auto;
                                                        margin-right: auto;
                                                      }
                                                    }
                                                  }
                                                }
                                            }
                                         }
                                     }
                                 }
                                }
                            }
                        }
                    }
                }
            }
        }
}

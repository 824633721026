/* add css module styles here (optional) */
body {
  blockquote {
    border-left: 5px solid #f1f1f1;
    padding-left: 5px;
  }
  pre {
    background: #f1f1f1;
    border-radius: 3px;
    padding: 7px 10px;
  }
}

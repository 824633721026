.visually-hidden {
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
  white-space: nowrap; /* added line */
}

.LoadingMessage {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
}

.LoadingMessage span {
  background-color: var(--button-gray-1);
  border-radius: 5px;
  padding: 0.8em 1.2em;
  color: var(--popup-text-color);
  font-size: 1.3em;
}
.logoutlink,
.logoutlink:hover {
  position: absolute;
  right: 150px;
  top: 18px;
  color: #000 !important;
  text-decoration: none;
}
.esmPch{
  background:#666 !important;
}
.aligncenter {
    justify-content: space-evenly;
    align-items: center;
    display: flex;
    cursor: pointer;
}

.fa-play {
    color: green;
    background: #fff;
    padding: 5px 5px 5px 10px;
    border-radius: 50%;
}

.fa-stop {
    color: red;
    background: #fff;
    padding: 5px 7px 5px 7px;
    border-radius: 50%;
}

.blink_me {
    animation: blinker 1s linear infinite;
}

@keyframes blinker {
    50% {
        opacity: 0;
    }
}

.bubble {
    position: fixed;
    width: 300px;
    //min-height: 150px;
    padding: 0px;
    top: 110px;
    right: 330px;
    //border-style: solid;
    //border-width: 1px;
    //border-color: #ccc;
    //border-radius: 15px;


  .tooltipbody{
    display: flex;
    //padding: unset;
    //background: unset;
    justify-content: space-around;
    border: 1px solid #E4E4E7;
    box-shadow: 0 2px 16px rgba(0, 0, 0, 0.08);
    border-radius: 6px;
    background-color: #FFFFFF;
    align-items: center;
    padding: 10px 16px;
    gap: 12px;

    svg {
      cursor: pointer;
    }
  }

  .annotation-text {
    border: none;
  }
}

textarea {
    width: 100%;
}

.vertical-line {
    border-left: 1px solid #ED863C;
    height: 4px;
    position: absolute;
    // left: 50%;
    // margin-left: -3px;
    //top: -5px;
}

.tooltiphead {
    background: #87876a;
    color: #fff;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    padding: 10px 0;
}

.tooltipbody {
    //background: #d9d9c2;
    background: #FFFFFF;
    padding: 20px;
}

.zindex {
    z-index: 999;
}
.shareScreen{
    position: fixed;
    bottom: 0;
    background-color:#ed863c;
    max-width:455px;
    margin: 0 auto;
    border: 1px solid #e9e9e9;
    left: 0;
    padding: 5px 20px;
    right: 0;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    bottom:14vh;
    p{
        span{
            margin-right:5px;
        }
        margin: 0px;
        font-size: 14px;
        color: #fff;
        font-weight: 600;
    }
    .rightSideBtn{
        margin-left:auto;
        .stopSharing{
            background: green;
            border-radius: 4px;
            font-style: normal;
            font-weight: 600;
            font-size: 12px;
            line-height: 18px;
            color: #fff;
            padding: 6px 15px;
            text-align: center;
            margin-right: 10px;
            cursor: pointer;
        }
        .hideSharing{
            background: #fff;
            border-radius:4px;
            font-style: normal;
            font-weight: 600;
            font-size:12px;
            line-height: 18px;
            color: #000;
            padding:6px 15px;
            text-align: center;
            cursor: pointer;
        }
    }
}
.customannotation {
  width: 200px !important;
  z-index: 9999;
  position: fixed !important;
  top: 5px !important;
  left: calc(50% - 100px) !important;
  bottom: unset !important;
  @media screen and (max-width:992px) {
    top: 8px !important;
    right:0;
  }
}

.annotation {
  background: #FFFFFF !important;
  box-shadow: none !important;
  border-radius: unset !important;
  display: block !important;
  padding: 4px 16px !important;

  .annotionbar-main {
    margin-top: 4px;
    margin-bottom: 4px;
  }

  .annotation-row-main {
    margin-bottom: 6px;
    margin-top: 6px;
    align-items: center;
  }
  .annotation-row {
    width: 100%;
    display: flex;
    justify-content: space-between;
    .annotation-left {
      text-align: left;
      //flex-grow: 1;
      .annotation-timer {
        border: 0.5px solid #B6B6B6;
        border-radius: 12px;
        padding: 4px 8px;
        display: flex;
        align-items: center;
        height: 20px;

        .aligncenternew {
          display: flex;
        }

        .annotation-time{
          margin-left: 6px;
          font-style: normal;
          font-weight: 400;
          font-size: 10px;
          line-height: 16px;
          letter-spacing: 0.02em;
          color: #808080;
        }
      }
    }

    .annotation-right {
      text-align: right;
      display: flex;

      .cursor-pointer {
        display: flex;
      }
    }

    .annotationbar {
      background: #FFFFFF !important;
      border: 0.5px solid #B6B6B6 !important;
      border-radius: 4px !important;
      height: 4px !important;

      .recorder-width {
        height: 4px !important;
        //border-radius: 4px !important;
        border-top-left-radius: 4px !important;
        border-bottom-left-radius: 4px !important;
        background: #F44336 !important;
      }
    }
  }
}

.annotation-action {
  display: flex;
  padding:0px 6px 0px 6px;
  background-color: #FFFFFF;
  position: relative;
  box-shadow: 2px 2px 24px rgba(40, 40, 40, 0.12);
  z-index: 9999;
  gap: 6px;
  width:max-content;
}

.annotation-notification-text {
  display: flex;
  top: 15px;
  font-size: 12px;
  padding: 4px 16px 4px 14px;
  background-color: unset;
  color: red;
  position: inherit;
  left: 25% !important;
  box-shadow: unset;
  z-index: 9999;
  gap: 6px;
  animation: blinker 4s linear infinite;
  border-radius:6px;
}

.annotation-action-no-host {
  display: flex;
  padding: 4px 16px 4px 14px;
  background-color: #FFFFFF;
  position: relative;
  box-shadow: 2px 2px 24px rgba(40, 40, 40, 0.12);
  z-index: 9999;
  gap: 26px !important;
  max-width: fit-content;
  @media screen  and (max-width:767px){
    gap:16px !important;
  }
}
.annotation-action .cursor-pointer svg{
  max-width:30px;
}
.cursor-pointer {
  cursor: pointer;
}

.recordingbar-bink {
  position: absolute;
  svg {
    position: absolute !important;
  }
}

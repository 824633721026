::placeholder {
  font-family: "Nunito", sans-serif;
}

.noteswrapper {
  position: absolute;
  right: 0;
  height: 100vh;
  background: #fff;
  z-index: 9999;
  width: 500px;
  @media screen and (max-width:767px) {
    width:100%;
  }

  .ql-editor {
    max-height: 150px !important;
  }
  .submit-main {
    text-align: right;
    .clear-btn {
      margin-right: 5px;
    }
    .btn {
      margin-top: 5px;
    }
  }

  .ql-editor {
    width: 100%;
    position: unset;
    top: unset;
    z-index: unset;
    left: unset;
    height: auto !important;
    display: block !important;
  }
}

.childOne {
  width: 100%;
  height: 100vh;
  background-color: #fff;
  box-shadow: 5px 5px 25px -5px rgba(0, 0, 0, 0.5);
  border-radius: 15px;
  margin: auto;
  padding: 20px 10px;
}

.attendees-wrapper {
  //width: 350px;
  white-space: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 19px;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  font-size: 12px;
}

.notesinput {
  //padding: 25px;
  padding: 5px 0 5px 0px;
  text-align: left;
  height: 30px;
  border: none;
  background: transparent;
  font-size: 20px;
  font-weight: 500;
  border-bottom: 2px solid #8566aa;
  outline: none;
  margin-right: 20px;
  width: 100%;
}

.AddBtn {
  width: 40px !important;
  height: 40px !important;
  background-color: #7E57C2 !important;
  color: white !important;
  box-shadow: 5px 5px 15px -5px rgba(0, 0, 0, 0.3);
  border-radius: 50% !important;
  padding: 5px 4px !important;
  min-width: auto !important;
}

.CloseBtn {
  position: absolute;
  right: 10px;
  top: 10px;
  font-size: 20px;
}

.textFont {
  font-family: verdana;
  font-size: 14px;
  font-weight: 500;
  list-style-type: none;
  padding: 10px 5px;
  max-height: 400px;
  overflow-y: auto;

  .notes-main {
    display: flex;
    justify-content: space-between;
    //border: 1px solid #ccc;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    border-radius: 5px;
    padding: 5px;
    margin-bottom: 5px;
  }

  .notes-sub {
    width: 90%;
    img {
      width: 200px;
      height: 150px;
    }
    p {
      margin-bottom: 0 !important;
    }
    display: block;

    font-size: 14px;
  }

  .action-buttons {
    .fa-edit {
      padding-right: 5px;
    }
  }

  //li {
  //  border-bottom: 1px solid #ccc;
  //  padding: 5px 0 5px 0px;
  //  font-size: 14px;
  //}
}

.childTwo {
  width: 100%;
  height: 100vh;
  background-color: #f4f4f4;
  box-shadow: 5px 5px 25px -5px rgba(0, 0, 0, 0.5);
  border-radius: 15px;
  margin: auto;
}

.delBtn {
  width: 100%;
  height: 7vh;
  background-color: #f4f4f4;
  box-shadow: 5px 5px 25px -5px rgba(0, 0, 0, 0.5);
  border-radius: 15px;
  margin: auto;
}

.margin-data {
  font-size: 12px;

  p {
    margin: 0px 0px 10px;
  }
}

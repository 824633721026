.excalidraw {
  .Island {
    --padding: 0;
    background-color: var(--bg-color-island);
    backdrop-filter: saturate(100%) blur(10px);
    box-shadow: var(--shadow-island);
    border-radius: 2px;
    //padding: calc(var(--padding) * var(--space-factor));
    position: relative;
    transition: box-shadow 0.5s ease-in-out;
    //position: absolute;
    //top: 245px;
    justify-content: center;
    align-items: flex-start;
    box-shadow: 2px 2px 24px rgba(40, 40, 40, 0.12);

    &.zen-mode {
      box-shadow: none;
    }

    &.select {
      border: none !important;
    }
  }

  .Island-2 {
    --padding: 0;
    background-color: var(--bg-color-island);
    backdrop-filter: saturate(100%) blur(10px);
    box-shadow: var(--shadow-island);
    border-radius: 0;
    padding: calc(var(--padding) * var(--space-factor));
    position: relative;
    transition: box-shadow 0.5s ease-in-out;
    position: absolute;
    top: 168px;
    right: -110px;
    min-width: 100px;

    &.zen-mode {
      box-shadow: none;
    }
  }

  .newHead {
    position: absolute;
    top: -20px;
    right: -20px;
  }

  .panelColumn {
    .right-border {
      display: flex;
    }

    .color-style{
      display: flex;
      border-right: 1px solid #F5F5F5 !important;
      padding: 8px 10px !important;

      .popover {
        top: 50px;
        left: auto;
      }
    }

    .right-border {
      //padding: 10px !important;
      border-right: 1px solid #F5F5F5 !important;
      align-items: center;
    }

    .p-10 {
      padding: 8px 10px !important;
    }

    fieldset {
      //padding: 10px !important;
      //border-right: 1px solid !important;
      display: flex;
      align-items: center;
      margin-top: 0 !important;

      .button-list {
      }

      select {
        -moz-appearance: none;
        -webkit-appearance: none;
        border: none !important;
        background: transparent;

        &:focus-visible {
          border: none !important;
          outline: none;
        }
      }
    }

    .groupings {
      .ToolIcon__icon{
        width: auto !important;
      }
    }

    .group-selection-div {
      button {
        padding: 10px;
      }
    }

    .buttonList {
      flex-wrap: nowrap !important;
      label {
        //margin-right: 0 !important;
      }

      &.buttonListIcon {
        label {
          padding: 0 !important;
          svg {
            width: 35px !important;
            height: 20px !important;
            opacity: 1 !important;
          }
        }

        .icon-italic{
          svg {
            height: 14px !important;
          }
        }

        .icon-underline {
          svg {
            height: 16px !important;
          }
        }

        .icon-strike {
          svg {
            height: 16px !important;
          }
        }
      }
    }
  }

  .fontSize-main {
    position: relative !important;
    padding: 8px 35px 8px 10px !important;

    .fontSizeTool {
      .MuiAutocomplete-inputRoot {
        padding: 0 !important;
        padding-right: 39px !important;

        .MuiAutocomplete-input {
          min-width: 50px !important;
        }
      }
      img {
        width: 15px !important;
        height: 15px !important;
        cursor: pointer;
      }

      .upArrow {
        position: absolute;
        top: 12px;
        right: 15px;
      }

      .downArrow {
        position: absolute;
        bottom: 12px;
        right: 15px;
      }
    }
  }

  .fontFamilyTool {
    .MuiSelect-select {
      min-width: 100px;
    }
    .MuiSelect-select:focus{
      background-color: transparent;
    }
    .MuiInput-underline:before {
      border: none !important;
    }
    .MuiInput-underline:after {
      border: none !important;
    }
  }

  .font-style-div {
    label {
      background: transparent !important;
    }

    .buttonList label:active {
      background-color: #FFFFFF !important;
    }
  }

  .font-weight-div {
    label {
      background: transparent !important;
    }

    .buttonList label:active {
      background-color: #FFFFFF !important;
    }
  }

  .font-family-style-main {
    input {
      display: none !important;
    }
  }

  .layer-toolbar-icon {

    padding: 0;

    .layers-div {
      min-width: 260px;
      flex-direction: column;

      button {
        padding: 12px;
        font-size: 14px;
        background: none;
        margin-inline-end: 0;

        &:focus, &:focus-visible{
          outline: none !important;
          box-shadow: none !important;
        }


        &:hover {
          background: #F2F8FF;
        }

        .direction-btn-label {
          display: flex;
          margin-left: 8px;
          width: 100%;

          .direction-left-content{
            text-align: left;
            flex-grow: 1;
          }

          .direction-right-content{
            text-align: right;
            font-size: 10px;
            color: #344054;
            align-self: center;
          }
        }
      }
    }
  }
  .arrow-head-main {
    button {
      background: transparent;

      svg {
        opacity: 1 !important;
      }
    }
  }

  .lockUnlockButton {
    &:active {
      background-color: transparent !important;
    }

    &:hover {
      background-color: transparent !important;
    }

    &:focus {
      box-shadow: none !important;
    }

    background: none;
    box-shadow: none;
  }

  .unlockLabelSpan {
    vertical-align: middle;
    color: #84829d;
  }
}

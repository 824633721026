.create-template-main {
  .createNewTeamOuter {
    width: 700px !important;
    @media screen and (max-width:767px) {
      width:95% !important;
    }
  }
  .infodataUser {
    label {
      font-size: 12px;
      margin-bottom: 4px;
    }

    .d-flex.flex-column {
      margin-bottom: 10px;

      input {
        //border:1px solid #ccc;
        //padding:5px 10px;
        width: 100%;
        box-sizing: border-box;
        outline: none;
        //border-radius:3px;
      }

      ::placeholder {
        font-family: 'Plus Jakarta Sans', sans-serif;
      }
    }
  }


  .d-flex.flex-column.todata {
    position: relative;
  }

  //.d-flex.flex-column.todata input{
  //    padding-left:35px;
  //    font-weight:600;
  //}
  .d-flex.flex-column.todata {
    .to-span {
      position: absolute;
      top: 9px;
      font-weight: bold;
      left: 10px;
    }
  }

  .row.todata {
    margin-bottom: 10px !important;
    border: 1px solid #B5B4B4 !important;
    border-radius: 6px !important;
    padding: 3px;
  }

  .d-flex.flex-column.todata .userInfoNew {
    background: #f7f7f7;
    border-radius: 6px;
    padding: 7px 20px;
  }

  .d-flex.flex-column.todata .userInfoNew i {
    margin-right: 5px;
    color: #000;
  }

  .react-tagsinput--focused {
    border-color: #cccccc !important;
  }

  .react-tagsinput {
    border: none !important;

    .react-tagsinput-input {
      width: 100% !important;
      margin-bottom: 3px !important;
    }

    span {
      input::placeholder {
        font-size: 16px !important;
      }
    }
  }

  .react-tagsinput-tag {
    background-color: #ffffff !important;
    border: none !important;
    color: #444444 !important;
    display: inline-block;
    font-size: 13px;
    font-weight: 400;
    margin-bottom: 3px !important;
    margin-right: 5px;
    padding: 5px;
  }

  .to-span-main {
    flex-wrap: inherit;
    align-content: center;
    display: flex;
    padding-left: 15px;
  }

  .access-selection {
    background: #f7f7f7 !important;
    border: none !important;
    color: #0094FF !important;
    outline: none !important;
    margin-left: 5px !important;
    width: 120px !important;

    .option-black {
      color: #444444;
    }

  }

  .btns-main {
    margin-top: 20px;
    justify-content: space-between;
  }

  .access-details-span {
    padding-left: 12px;
  }

  .avatar-wrapper {
    position: relative;
    height: 120px;
    width: 120px;
    overflow: hidden;
    transition: all .3s ease;
    background: #6B788B;
    border-radius: 6px;
    &:hover {
      transform: scale(1.05);
      cursor: pointer;
    }
    &:hover .profile-pic {
      opacity: .5;
    }
    .profile-pic {
      height: 100%;
      width: 100%;
      transition: all .3s ease;
      &:after {
        font-family: FontAwesome;
        content: "\f007";
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        position: absolute;
        font-size: 190px;
        background: #ecf0f1;
        color: #34495e;
        text-align: center;
      }
    }
    .upload-button {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      .fa-arrow-circle-up {
        position: absolute;
        font-size: 234px;
        top: -17px;
        left: 0;
        text-align: center;
        opacity: 0;
        transition: all .3s ease;
        color: #34495e;
      }
      &:hover .fa-arrow-circle-up {
        opacity: .9;
      }
    }
  }

  .fileUploadInfo {
    width: 100%;
    display: inline-block;
    position: relative;
    margin-top: 12px;

    .fileUploadInfo {
      background: #4262ff;
      color: #fff;
      font-size: 12px;
      text-align: center;
      width: 102px;
      padding: 10px;
      border-radius: 6px;
      position: relative;
      float: left;
    }

    .file-upload {
      width: 105px;
      margin: 0;
      float: left;
      margin-top: 20px;
      background: #0094FF;
      overflow: hidden;
      border: none;
      outline: none;
      position: absolute;
      left: 3px;
      height: 35px;
      opacity: 0;
      cursor: pointer;
    }
  }

  .access-type {
    width: auto !important;
  }

  .inputDataTeam {
    margin-bottom: 5px;
    select {
      outline: none;
      display: inline-block;
      box-sizing: border-box;
      border: 1px solid;
      border-radius: 4px;
      color: #050038;
      background-color: white;
      width: 100%;
      font-style: normal;
      font-stretch: normal;
      -webkit-letter-spacing: normal;
      box-sizing: border-box;
      padding: 8px 15px;
      box-sizing: border-box;
      margin-top: 5px;
      line-height: 18px;
    }
  }

  label {
    color: #444444;
  }
  input {
    color: #444444;
  }
  .react-tagsinput {
    border: 1px solid !important;
    border-radius: 4px !important;
    margin-top: 5px;
    input {
      border: none !important;
      padding: 5px !important;
    }

  }
}

@import "../css/_variables";

// this is loosely based on the longest hint text
$wide-viewport-width: 1000px;

.excalidraw {
  .HintViewer {
    pointer-events: none;
    box-sizing: border-box;
    position: absolute;
    display: flex;
    justify-content: center;
    left: 0;
    top: 100%;
    max-width: 100%;
    width: 100%;
    margin-top: 6px;
    text-align: center;
    color: $oc-gray-6;
    font-size: 0.8rem;

    @media #{$is-mobile-query} {
      position: static;
      padding-right: 2em;
    }
    @media screen and (max-width:767px) {
      padding-top: 71px;
      padding-left: 45px;
    }
    > span {
      padding: 0.2rem 0.4rem;
      background-color: var(--overlay-background-color);
      border-radius: 4px;
    }
  }
}

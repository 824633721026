.display {
  width: 400px;
  margin: 50px auto;
  background: #fff;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
}

.span {
  font-size: 30px;
}

.buttonsBox {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 10px 0;
}

.buttonsBox .button {
  margin-left: 20px;
  border: none;
  padding: 10px;
  background-color: #689eca;
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  border-radius: 5px;
}

.inputBoxhours {
  width: 100%;
}

.inputBoxminutes {
  width: 100%;
}
.inputBoxseconds {
  width: 100%;
}

.Box {
  width: 100%;
  display: flex;
  flex-direction: row;
  margin: 10px 0;
  box-shadow: rgb(200, 208, 231) 3.2px 3.2px 8px 0px inset,
    rgb(255, 255, 255) -3.2px -3.2px 8px 0px inset;
}
.close {
  float: right;
}

.cmp-download-recording {
    border-radius: 10px;
    background-color: white;
    width: 35%;
    padding: 20px;
    font-family: Arial, Helvetica, sans-serif;
    position: relative;
    button {
        background-color: #ED863C;
        border: none;
    }
    :focus-visible {
      outline: none !important;
    }
}
.download-recording {
  border-radius: 10px;
  background-color: white;
  width: 70%;
  padding: 20px;
  font-family: Arial, Helvetica, sans-serif;
  position: relative;
  button {
    background-color: #ED863C;
    border: none;
  }
}
.recording-list-div {
  max-height: 300px;
  overflow-x: auto;
  //border: 1px solid #0000001a;
  //border-radius: 12px;
  li {
    font-size: 14px;
    cursor: pointer;
  }
}

.annotation-list-div {
  max-height: 300px;
  overflow-x: auto;
  //border: 1px solid #0000001a;
  //border-radius: 12px;
  li {
    font-size: 14px;
    cursor: pointer;
  }
}

.recording-video-div {
  margin-left: 15px;
  margin-right: 15px;
  //border: 1px solid #0000001a;
  //border-radius: 12px;

}

.react-player{
  max-height: 300px;
  background: rgba(0,0,0,.1);
  video {
    max-height: 300px;
  }
}

.recording-video-div {
  .annotionbar-main {
    position: relative;
    margin-top: 20px;
    margin-bottom: 4px;
  }

  .vertical-line {
    border-left: 2px solid #ED863C;
    height: 10px;
    position: absolute;
    cursor: pointer;
    // left: 50%;
    // margin-left: -3px;
    //top: -5px;
  }

  .annotation-row {
    width: 100%;
    display: flex;
    justify-content: space-between;

    .annotation-left {
      text-align: left;
      //flex-grow: 1;
      .annotation-timer {
        //border: 0.5px solid #B6B6B6;
        //border-radius: 12px;
        padding: 4px 8px;
        display: flex;
        align-items: center;
        height: 20px;

        .aligncenternew {
          display: flex;
        }

        .annotation-time {
          margin-left: 6px;
          font-style: normal;
          font-weight: 400;
          font-size: 10px;
          line-height: 16px;
          letter-spacing: 0.02em;
          color: #808080;
        }
      }
    }

    .annotation-right {
      text-align: right;
      display: flex;

      .cursor-pointer {
        display: flex;
      }
    }

    .annotationbar {
      background: #FFFFFF !important;
      //border: 0.5px solid #B6B6B6 !important;
      //border-radius: 4px !important;
      height: 4px !important;
      width: 100%;

      .recorder-width {
        height: 4px !important;
        //border-radius: 4px !important;
        //border-top-left-radius: 4px !important;
        //border-bottom-left-radius: 4px !important;
        background: #F44336 !important;
      }
    }
  }
}
.MuiTooltip-popper {
  z-index: 9999999 !important;
}

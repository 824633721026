.cmp-message {
    span {
        display: block;
        padding: 5px;
        border-radius: 10px;
        margin-bottom: 5px;
        width: fit-content;
        small {
            display: block;
            font-weight: bold;
        }
    }
    .own-message {
        background: #dce6f0;
        color: black;
        text-align: right;
        margin-left: auto;
        small {
            color: #708bfc;
        }
    }
    .others-message {
        background: #708bfc;
        color: white;
        small {
            color: white;
        }
    }
}
.dataSliderTop{
    width:100%;
    display:inline-block;
    font-style: normal;
    font-weight: 700;
    font-size: 64px;
    line-height: 74px;
    color: #0F1A50;
    b{
      color:#ED863C;
      font-weight: 700;
    }
    p{
        margin:0px;
        font-size:18px;
        line-height:25px;
    }
}
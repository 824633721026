/* The keypad height is 80% of total height of the calculator.
This allows the hight of the calculator to scale on smaller screens. */
.Keypad {
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 2.5 1 0;
}

.Keypad-row {
  display: flex;
  flex: 1 0 20%;
}

.Keypad-row:last-of-type {
  border-radius: 0 0 10px 10px;
  overflow: hidden;
}

@media (max-width: 450px), (max-width: 740px) and (max-height: 450px) {
  .Keypad-row:last-of-type {
    border-radius: 0;
  }
}

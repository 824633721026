.userInfo {
  display: flex;
  flex-direction: column;

  .commentsTwo {
    display: flex;
    align-items: center;
    margin-top: 8px;

    .fullName {
      display: flex;
      margin-left: 10px;
      font-size: 10px;
      font-weight: 600;
    }
  }
}
.halfDiv {
  display: flex;
  justify-content: space-between;
}
.replyBtn {
  background-color: transparent;
  border: none;
  color: gray;
  outline: none;
  font-weight: 600;
  font-size: 10px;
  margin: 2px 5px 0px 0px !important;
  width: 70px;
  padding: 5px;
  border-radius: 4px;

  span {
    font-size: 8px !important;
  }

  &:hover {
    outline: none !important;
    background-color: white !important;
  }
  &:focus {
    outline: 0;
  }
}
.userActions {
  margin-top: 20px;

  .actionsBtn {
    background-color: transparent;
    border: none;
    padding: 6px;
    border-radius: 50%;
    cursor: pointer;
    &:focus {
      outline: none !important;
    }
    &:hover {
      outline: none !important;
      background-color: white;
      border-radius: 50%;
    }
  }
}
.userLink {
  display: flex;
  text-decoration: none;
  color: inherit;
  align-items: center;
  .imgdefault {
    width: 28px;
    height: 28px;
    border-radius: 14px;
  }
}
.replysection {
  display: flex;
  flex-direction: column;
}
.infoStyle {
  margin-left: 36px;
  font-size: 10px;
  width: 400px;
  word-wrap: break-word;
  p {
    margin: 0px;
  }
}
.replyIcon {
  background-image: url("../../assets/reply.svg");
  width: 16px;
  height: 13px;
  filter: invert(67%) sepia(0%) saturate(0%) hue-rotate(110deg) brightness(85%)
    contrast(84%);
  margin-right: 5px;
  position: absolute;
}
.optionIcon {
  background-image: url("../../assets/options.svg");
  width: 6px;
  height: 6px;
  filter: invert(24%) sepia(0%) saturate(0%) hue-rotate(155deg) brightness(98%)
    contrast(93%);
  padding: 7px;
  background-repeat: no-repeat;
}
.szh-menu {
  font-family: sans-serif;
  font-size: 0.925rem;
  user-select: none;
  box-shadow: 1px 1px 20px 1px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  padding: 6px !important;
  min-width: 7rem;
  left: -70px;
  top: -5px;
  color: black;

  .szh-menu__item {
    padding: 5px;
  }
  .szh-menu__item:hover {
    color: black;
    background-color: #f5f5f5;
  }
}

.react-responsive-modal-modal {
  max-width: 350px !important;
  h2,
  p {
    text-align: center;
  }
}
.deleteBtns {
  display: flex;
  justify-content: center;
}
.delete {
  border: none;
  border-radius: 4px;
  background-color: red;
  padding: 5px 10px;
  color: white;
  font-weight: bolder;
  font-size: 14px;
  cursor: pointer;
}
.cancel {
  border: none;
  border-radius: 4px;
  background-color: grey;
  padding: 5px 10px;
  color: white;
  font-weight: bolder;
  font-size: 14px;
  cursor: pointer;
  margin-left: 10px;
}
.hash_tag {
  font-size: 10px !important;
  font-weight: 900;
}

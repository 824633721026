@import "../css/variables";

.excalidraw {
  --ImageExportModal-preview-border: #d6d6d6;

  &.theme--dark {
    --ImageExportModal-preview-border: #5c5c5c;
  }
  .Dialog__content {
    font-family: 'Plus Jakarta Sans', sans-serif !important;
  }
  .ImageExportModal {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-family: 'Plus Jakarta Sans', sans-serif !important;

    & h3 {
      font-family: 'Plus Jakarta Sans', sans-serif !important;
      font-style: normal;
      font-weight: 700;
      font-size: 1.313rem;
      line-height: 130%;
      padding: 0;
      margin: 0;

      @include isMobile {
        display: none;
      }
    }

    & > h3 {
      display: none;

      @include isMobile {
        display: block;
      }
    }

    @include isMobile {
      flex-direction: column;
      height: calc(100vh - 5rem);
    }

    &__preview {
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      align-items: center;
      height: 360px;
      width: 55%;

      margin-right: 1.5rem;

      @include isMobile {
        max-width: unset;
        margin-right: unset;

        width: 100%;
        height: unset;
        flex-grow: 1;
      }

      &__filename {
        & > input {
          margin-top: 1rem;
        }
      }

      &__canvas {
        box-sizing: border-box;
        width: 100%;
        height: 100%;
        display: flex;
        flex-grow: 1;
        justify-content: center;
        align-items: center;

        background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAMUlEQVQ4T2NkYGAQYcAP3uCTZhw1gGGYhAGBZIA/nYDCgBDAm9BGDWAAJyRCgLaBCAAgXwixzAS0pgAAAABJRU5ErkJggg==")
          left center;

        border: 1px solid var(--ImageExportModal-preview-border);
        border-radius: 12px;

        overflow: hidden;
        padding: 1rem;

        & > canvas {
          max-width: calc(100% - 2rem);
          max-height: calc(100% - 2rem);

          filter: none !important;

          @include isMobile {
            max-height: 100%;
          }
        }

        @include isMobile {
          margin-top: 24px;
          max-width: unset;
        }
      }
    }

    &__settings {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      gap: 18px;
      font-family: 'Plus Jakarta Sans', sans-serif !important;

      @include isMobile {
        margin-left: unset;
        margin-top: 1rem;
        flex-direction: row;
        gap: 6px 34px;

        align-content: flex-start;
      }

      &__setting {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        @include isMobile {
          flex-direction: column;
          align-items: start;
          justify-content: unset;
          height: 52px;
        }

        &__label {
          display: flex;
          flex-direction: row;
          align-items: center;

          //font-family: "Assistant";
          font-weight: 600;
          font-size: 1rem;
          line-height: 150%;

          & svg {
            width: 20px;
            height: 20px;
            margin-left: 10px;
          }
        }

        &__content {
          display: flex;
          height: 100%;
          align-items: center;
        }
      }

      &__buttons {
        flex-grow: 1;
        flex-wrap: wrap;
        display: flex;
        flex-direction: row;
        gap: 11px;

        align-items: flex-end;
        align-content: flex-end;

        @include isMobile {
          padding-top: 32px;
          flex-basis: 100%;
          justify-content: center;
        }
      }
    }
  }
}

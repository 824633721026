.carousel-indicators.newslider [data-bs-target]{
    width: 20px;
    height: 4px;
    border-radius:0px;
}
.carousel-indicators{
    bottom:-66px !important;
}
.carousel-indicators.newslider{
    button{
        margin:0px 10px !important;
    }
    button.active{
        background-color:#3636C9 !important;
    }
}
.carousel-indicators [data-bs-target]{
    background-color:#ccc !important;
}
.userInfoDataSlider{
    width:100%;
    background-color:#fbe6d7;
    border-radius:40px;
    .innerSlider{
        width:100%;
        display:flex;
        @media screen and (max-width: 750px) {
            display:inline-block;
        }
        img{
            max-width:200px;
            width:auto;
            @media screen and (max-width: 750px) {
                max-width:100%;
            }
        }
        .contentDataUsernew{
            width: 100%;
            margin-left: auto;
            padding: 30px;
            box-sizing: border-box;
            @media screen and (max-width: 750px) {
                width:100%;
                margin-top:0;
                padding:25px;
                box-sizing: border-box;
            }
            p{
                font-style: normal;
                font-weight: 500;
                font-size: 20px;
                line-height: 140%;
                text-align: justify;
                color: #7D7D7D;
                min-height:180px;
            }
            .userNamedata{
                width:100%;
                display:inline-block;
                .borderData{
                    width:120px;
                    height:4px;
                    background-color:#5252E5;
                    margin-bottom:15px;
                }
                h4{
                    font-style: normal;
                    font-weight: 600;
                    font-size: 22.9153px;
                    line-height: 34px;
                    color: #3636C9;
                }
                h6{
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14.1017px;
                    line-height: 140%;
                    color: #3636C9;
                }
            }
        }
    }
    .innerSlider.ownerImg{
        img{
            margin:30px 30px 30px;
            border-radius:10px;
            box-shadow:0px 0px 50px #ccc;
            height:340px;
        }
    }
}
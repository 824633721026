.move-board {
  border-radius: 16px;
  background-color: white;
  width: 40%;
  padding: 25px;
  font-family: Arial, Helvetica, sans-serif;
  position: relative;
  outline: none;

  button {
    background-color: #0073e7;
    border: none;
    border-radius: 6px;
    padding: 5px 20px;
  }

  .headinglink {
    font-size: 20px;
    line-height: 36px;
    display: flex;
    align-items: flex-start;
    color: #14143B;
    justify-content: flex-start;
    font-weight: 600;
    text-transform: unset !important;
    font-family: 'Plus Jakarta Sans', sans-serif !important;
  }
}

.infodataMoveBoard {
  label {
    font-size: 12px;
    margin-bottom: 4px;
  }

  .d-flex.flex-column {
    margin-bottom: 10px;

    input {
      //border:1px solid #ccc;
      //padding:5px 10px;
      width: 100%;
      box-sizing: border-box;
      outline: none;
      //border-radius:3px;
    }

    ::placeholder {
      font-family: 'Plus Jakarta Sans', sans-serif;
    }
  }
}

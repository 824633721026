.create-team-board-modal {
  .board-name-main {
    line-height: 36px;
    .board-name {
      border: 1px solid #ccc;
      padding: 0 10px;
      border-radius: 6px;
      margin-bottom: 15px;
    }
  }

  .project-name-main {
    line-height: 36px;
    .project-name {
      border: 1px solid #ccc;
      padding: 0 10px;
      border-radius: 6px;
      margin-bottom: 15px;
    }
  }
}

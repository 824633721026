.Display {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  flex: 1 1 0;
  padding: 10px 20px 10px 10px;
  background: linear-gradient(to bottom, #2f2c2c, #444141);
  border: solid 1px #504b4b;
  border-bottom-width: 0;
  border-radius: 10px 10px 0 0;
  font-size: 36px;
  font-weight: 300;
  color: #f1f1f1;
}

.Display-unit {
  margin-right: auto;
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 0.5px;
}

@media (max-width: 450px), (max-width: 740px) and (max-height: 450px) {
  .Display {
    border-radius: 0;
  }
}

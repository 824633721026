.testimonialInfo .Apps {
    font-family: sans-serif;
    color: white;
    box-sizing: border-box;
  }
  
  .react-tabs {
    width:100%;
    display:inline-block;
    color: #fff;
  }
  
  .react-tabs__tab-list {
    display: flex;
    width:100%;
    margin: 0;
    padding: 0;
    color: white;
    margin-bottom:40px;
    overflow:auto;
    @media screen and (max-width:768px) {
      margin-bottom:0px;
    }
  }
  
  .react-tabs__tab {
    list-style: none;
    padding:12px 6px;
    cursor: pointer;
    color: #bbb;
    border:1px solid #cccc;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 30px;
    min-width:200px;
    color: #202020;
    border-radius:40px;
    margin-right:20px;
    outline:none !important;
    text-align:center;
    @media screen and (max-width:768px) {
      line-height:20px;
      font-size:12px;
    }
  }
  .react-tabs__tab--selected {
    background: rgba(15, 26, 80, 0.1);
    color: #0F1A50;
    outline:none !important;
  }
  .react-tabs__tab--selected p{
      display:block !important;
      transition:3s ease-in-out;
  }
  .react-tabs__tab-panel {
    display: none;
    width: 100%;
    margin-left: auto;
  }
  
  .react-tabs__tab-panel--selected {
    display: block;
  }
  
  .panel-content {
    text-align: center;
    width:100%;
    display:flex;
    flex-wrap: wrap;
    @media screen and (max-width: 767px) {
      display:inline-block;
    }
    .leftUlLiData.new{
      width:30%;
      @media screen and (max-width:992px) {
        width:100% !important;
      }
    }
  }
  .leftUlLiData ul{
padding-left:10px;
margin-bottom:20px;
  }
  .leftUlLiData a{
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 26px;
    color: #0F1A50 !important;
    display: flex;
    align-items: center;
    margin: 15px 0px 0px;
    display:none;
    img{
      width: 16px;
    margin-left: 16px;
    }
  }
  .leftUlLiData ul li {
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 26px;
    color: #75757B;
    display: flex;
    text-align:left;
    margin-bottom:20px;
    align-items: baseline;
    img{
      width:15px !important;
      max-width:fit-content;
      margin-right:16px;
    }
  }
  
.imageInfoTab{
  width:60%;
  margin-left:auto;
  @media screen and (max-width:992px) {
    width:100% !important;
  }
  img{
    max-width:100%;
  }
}
.leftUlLiData.new{
  p{
    color:#000;
    font-size:20px;
    text-align:left;
  }
}
html {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}
a, button, input, textarea {
  -webkit-tap-highlight-color: rgba(0,0,0,0)
}

button {
  -webkit-appearance: none;
  box-shadow: none;
  border: none;
}

button,
input {
  box-shadow: none;
  border: none;
  background: transparent;
  cursor: pointer;
}

button:focus,
input:focus,
button:hover,
input:hover,
button:active,
input:active {
  outline: none;
}

a,
a:hover,
a:active,
a:visited,
a:focus {
  color: inherit;
  text-decoration: none;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
}

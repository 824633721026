.mainBox {
  border-radius: 10px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  max-width: 900px;
  margin: 30px auto;
  gap: 100px;
  padding: 20px;
  background-color: white;
  font-size: 15px;
}

.box {
  min-height: 230px;
  margin-top: 30px;
}

#output {
  margin: 0%;
}

.outputResult {
  border: 1px solid black;
  //border-radius: 20px;
  padding: 5px;
  font-size: 12px;
  color: #4C4C4C;
  font-family: "Plus Jakarta Sans";
  font-weight: 500;
  //background: #001829;
  &::placeholder {
    font-family: "Plus Jakarta Sans";
    font-weight: 500;
    font-size: 12px;
  }
}

textarea {
  //width: 85%;
  min-height: 230px;
  resize: none;
}

.navbar {
  //background-color: #a7233a;
  display: flex;
  justify-content: center;
}

.iconBox {
  display: flex;
  justify-content: end;
  margin-top: 0;
  font-size: 8px;

  p {
    margin-bottom: 0;
  }
}

.icon {
  width: 30px;
  height: 30px;
  //color: #a7233a;
  padding: 10px;
  border-radius: 50%;
  transition: all 1s ease-in-out;
  margin-left: 10px;
}

.icon:hover {
  //background-color: #a7233a;
  color: white;
}

:root {
  --background-gradient: linear-gradient(30deg, #f39c12 30%, #f1c40f);
  --gray: #34495e;
  --darkgray: #011e33;
}

select {
  /* Reset Select */
  //appearance: none;
  outline: 0;
  //border: 0;
  box-shadow: none;
  /* Personalize */
  font-size: 15px;
  font-family: "Plus Jakarta Sans";
  flex: 1;
  padding: 0 2em;
  //color: #fff;
  //background-color: var(--darkgray);
  //background-image: none;
  cursor: pointer;
}

/* Remove IE arrow */
select::-ms-expand {
  display: none;
}

/* Custom Select wrapper */
.select {
  position: relative;
  display: flex;
  width: 100%;
  height: 3em;
  //border-radius: 20px;
  overflow: hidden;
}

.tagLine {
  display: flex;
  justify-content: center;
  margin-top: 70px;
}

#madeByMohit {
  width: 0ch;
  overflow: hidden;
  white-space: nowrap;
  animation: tagline 10s  infinite alternate;;
  border-right: 3px solid white;
}

@keyframes tagline {
  0% {
    width: 0ch;
  }
  50% {
    width: 31ch;
  }
}


@media screen and (max-width: 800px) {
  .mainBox {
    gap: 30px;
  }

  .box {
    min-height: 180px;
  }

  textarea {
    min-height: 180px;
  }
}

@media screen and (max-width: 600px) {
  .mainBox {
    grid-template-columns: repeat(1, 1fr);
    gap: 60px;
  }

  .box {
    min-height: 150px;
    margin-top: 20px;
  }

  textarea {
    min-height: 150px;
  }
}

@media screen and (max-width: 400px) {
  .navbar>h1{
    font-size: 28px
  }
}

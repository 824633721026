math-field {
  width: 300px !important;
}
.formula-editor-main {
  padding-right: 12px;
  padding-top: 6px;
}
.ML__keyboard {
  display: flex !important;
  justify-content: space-around !important;
}

.MLK__backdrop {
  width: 75% !important;
}

.MLK__plate {
  font-size: 10px !important;
}

.MLK__keycap {
  font-size: 10px !important;
}

.action .hide-shift svg {
  font-size: 10px !important;
}

.shift .bottom svg {
  font-size: 10px !important;
}

.MLK__rows {
  gap: 0px !important;
}

.MLK__rows > .row {
  height: 45px !important;
  gap: 2px !important;
}

.MLK__rows > .row div {
  height: 40px !important;
  width: 65px !important;
}

.MLK__shift {
  font-size: 10px !important;
}

.action svg{
  width: 10px !important;
  height: 10px !important;
  min-height: 10px !important;
}

.svg-glyph {
  width: 10px !important;
  height: 10px !important;
  min-height: 10px !important;
}

.ML__edit-toolbar {
  display: none !important;
}

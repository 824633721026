.wrapper-1qfQg {
  //position: absolute;
  z-index: 71;
  background-color: #ffffff;
  border-radius: 4px;
  box-shadow: 0px 4px 16px rgba(5, 0, 56, 0.12);
}
.addSame-2SD0r {
  position: relative;
  padding: 8px;
  margin: 12px 15px 0;
  border-radius: 4px;
  border: solid 1px rgba(0, 0, 0, 0.2);
  font-size: 12px;
  line-height: 15px;
  opacity: 0.6;
  color: #090909;
  cursor: pointer;
}

svg:not(:root) {
  overflow: hidden;
}

 .addSameIcon-25W6t {
   position: absolute;
   bottom: 5px;
   right: 4px;
 }

 .icon_large-2wsCL {
   width: 24px;
   height: 24px;
 }

 .icon-3CqDw {
   display: block;
 }

.widgetType-1DD_Q {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-line-pack: start;
  align-content: flex-start;
  padding: 12px 0;
  width: 148px;
}

.widgetType-1DD_Q .widgetType__items-YSmVJ {
  width: 100%;
  -ms-flex-pack: center;
  justify-content: center;
  display: -ms-grid;
  display: grid;
  //-ms-grid-columns: (24px)[3];
  grid-template-columns: repeat(3, 24px);
  gap: 16px;
}

.widgetType-1DD_Q .widgetType__item-2Ymec {
  width: 24px;
  height: 24px;
  min-width: unset;
  cursor: pointer;
}

.widgetType-1DD_Q .widgetType__icon-2yRpx {
  -o-object-fit: contain;
  object-fit: contain;
}

.cmp-video-player {
    border-radius: 10px;
    background-color: white;
    max-width:900px;
    padding:0px;
    font-family: Arial, Helvetica, sans-serif;
    position: relative;
    height:auto;
    .bgData{
        background-color:#f5f5f5;padding:10px;margin-bottom:10px;
    }
    h2{
        text-align: center;
        text-transform:uppercase;
        font-size:24px;
        line-height:36px;
        background-color: #ED863C;
        font-weight:600;
        color:#fff;
        padding:5px;
    }
    button {
        background-color: #ed863c;
        border: none;
    }
    span {
        font-size:14px;
    }
    span.active {
        color: #ed863c;
    }
    .recordingData{
        height: 69vh;
        overflow: auto;
        margin: 0px 10px 10px;
        border: 1px solid #ED863C;
        background: #f5f5f5;
        ul {
            padding:0px;
            li{
                list-style: none;
                cursor: pointer;
                border-bottom: 1px solid #fff;
                padding: 5px 10px;
                font-size:12px;
            }
        }
    }
    .textPartDataVideo{
        height: 69vh;
        overflow: auto;
        margin: 0px 10px 10px;
        border: 1px solid #ED863C;
        background: #f5f5f5;
        h3{
            padding:5px 10px;
            border-bottom:1px solid #fff;
        }
        span{
            border-bottom:1px solid #fff;
            padding:5px 10px;
        }
    }
    .crossDataVideoNew {
        position: relative;
        display: inline-block;
        width: 12px;
        height: 12px;
        overflow: hidden;
        position: absolute;
        right: 7px;
        top: 8px;
        cursor: pointer;
    }
    .crossDataVideoNew:after,
    .crossDataVideoNew:before {
        height: 2px;
        margin-top: -2px;
    }
    .crossDataVideoNew:after,
    .crossDataVideoNew:before {
        content: "";
        position: absolute;
        height: 2px;
        width: 100%;
        top: 50%;
        left: 0;
        margin-top: -1px;
        background: #fff;
    }
    .crossDataVideoNew:after {
        -webkit-transform: rotate(-45deg);
        -moz-transform: rotate(-45deg);
        -ms-transform: rotate(-45deg);
        -o-transform: rotate(-45deg);
        transform: rotate(-45deg);
    }
    .crossDataVideoNew:before {
        -webkit-transform: rotate(45deg);
        -moz-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        -o-transform: rotate(45deg);
        transform: rotate(45deg);
    }
}